import { Container, Row, Col } from 'react-grid-system';
import './Papers.css';


const Papers = () => {
  return (
   <section id="papers">    

    <Container>
        <Row>

        <Col className="paper-head" sm={12}>
           <h2>Papers</h2>
           <p>Collected papers from the working team.</p>
        </Col>
        </Row>

        <Row>

            <Col className="paper" sm={6} md={3}>
              <div>
            <span><a href="https://dl.acm.org/doi/abs/10.1145/3512957" target="_blank">Power Dynamics and Value Conflicts in Designing and Maintaining Socio-Technical Algorithmic Processes. </a></span>
              <p>Park, Joon Sung, Karrie Karahalios, Niloufar Salehi, Motahhare Eslami (2022, April).</p> 
              <cite>In Proceedings of the ACM on Human-Computer Interaction (CSCW) 6</cite>
              </div>
            </Col>

            <Col  className="paper" sm={6} md={3}>
            <div>
            <span><a href="https://proceedings.mlr.press/v162/carroll22a.html" target="_blank">Estimating and Penalizing Induced Preference Shifts in Recommender Systems.</a></span>
            <p>
            Carroll, Micah D., Anca Dragan, Stuart Russell, and Dylan Hadfield-Menell. </p>
            <cite> In International Conference on Machine Learning, pp. 2686-2708. PMLR, 2022.
            </cite>
            </div>
            </Col>
              
            <Col  className="paper" sm={6} md={3}>
            <div>
            <span><a href="https://dl.acm.org/doi/abs/10.1145/3442188.3445923?casa_token=S8P1iExVOYAAAAAA:iY7W6x3nf7PVZedvAhOPq2Kup3mzspbuSQWF3rtXl0pMcirJgz5erOA9Z837skQRIV9nqRvAMv1Q" target="_blank">Formalizing trust in artificial intelligence: Prerequisites, causes and goals of human trust in ai. </a></span>
            <p>Jacovi, A., Marasović, A., Miller, T., & Goldberg, Y. (2021, March).</p>
            <cite>In Proceedings of the 2021 ACM conference on fairness, accountability, and transparency (pp. 624-635). 
              </cite>
              </div>
              </Col>
              <Col  className="paper" sm={6} md={3}>
                <div>
              <span><br /><a href="https://www.nature.com/articles/s41586-018-0637-6." target="_blank">The moral machine experiment.</a></span>
              <p>
              Awad, Edmond, Sohan Dsouza, Richard Kim, Jonathan Schulz, Joseph Henrich, Azim Shariff, Jean-François Bonnefon, and Iyad Rahwan.</p>
              <cite>Nature 563, no. 7729 (2018): 59-64.
              </cite>
              </div>
              </Col>

              <Col  className="paper" sm={6} md={3}>
              <div>
              <span><a href="https://www.amazon.com/AI-We-Trust-Predictive-Algorithms/dp/1509548815" target="_blank"> In AI We Trust: Power, Illusion and Control of Predictive Algorithms.</a></span>
              <p>
              Nowotny, Helga. </p>
              <cite>Polity 2021.
              </cite>
              </div></Col>
              </Row>
       
    </Container>

    </section>
  );
}

export default Papers;
