import React, {Component, useEffect, useState } from 'react';
import Scroll from 'react-scroll';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import './Footer.css';


const Link = Scroll.Link

function  Footer(){

	return( 


    <section id="footer">
	<Container id="contact">
	    <Row>
		<Col sm={6}>
        The Institute for Trusted Intelligence in Society
		</Col>
        <Col sm={6}>
        <a href="mailto:jevans@uchicago.edu">Contact</a>
		</Col>
		</Row>

	</Container>
    </section>
	)
}

export default Footer;