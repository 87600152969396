import React, { Component } from "react";
import { Container, Row, Col } from 'react-grid-system';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './Slideshow.css';

class Slideshow extends Component {
    constructor() {
      super();
      this.slideRef = React.createRef();
      this.back = this.back.bind(this);
      this.next = this.next.bind(this);
      this.state = {
        current: 0
      };
    }
  
    back() {
      this.slideRef.current.goBack();
    }
  
    next() {
      this.slideRef.current.goNext();
    }
  
    render() {
      const properties = {
        duration: 5000,
        autoplay: false,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: "ease",
        indicators: (i) => <div className="indicator"> • </div>
      };
      const slideImages = [
        "./img/1.jpg",
        "./img/2.jpg",
        "./img/3.jpg",
        "./img/4.jpg",
        "./img/5.jpg",
        "./img/6.jpg",
        "./img/7.jpg",
        "./img/8.jpg",
        "./img/9.jpg",
        "./img/10.jpg",
        "./img/11.jpg",
        "./img/12.jpg",
        "./img/13.jpg",
        "./img/14.jpg",
        "./img/15.jpg",
        "./img/16.jpg",
        "./img/17.jpg",
        "./img/18.jpg",
        "./img/19.jpg",
        "./img/20.jpg",
        "./img/21.jpg",
        "./img/22.jpg",
        "./img/23.jpg",
        "./img/24.jpg",
        "./img/26.jpg",
        "./img/27.jpg",
        "./img/28.jpg",
        "./img/30.jpg"
      ];
      return (
        <section id="ic2s2">
        <Container>
        <Row><Col>
        <div className="App">
          <h2>The ICS2 Event</h2>
          <div className="slide-container">
            <Fade ref={this.slideRef} {...properties}>
              {slideImages.map((each, index) => (
                <div key={index} className="each-slide">
                  <img className="lazy" src={each} alt="sample" />
                </div>
              ))}
            </Fade>
          </div>
  
          <div className="slide-container buttons">
            <button onClick={this.back} type="button">
              prev
            </button>
            <button onClick={this.next} type="button">
              next
            </button>
          </div>
        </div>
        </Col>
        </Row></Container>
        </section>
      );
    }
  }
  
  export default Slideshow;
  
