import confimg from './img/ai-eye.png';
import datasci from './img/data-science.svg';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import './App.css';

const Events = () => {


  return (
    <section id="events">
      <Container>
          <Row>

            <Visible md lg xl xxl><Col md={1}></Col></Visible>

            <Col id="summit" xs={12} md={5}>
              <Col xs={12}>
                <Row>

               <Col xs={4}><img src={confimg} /></Col>
               
               <Col xs={8}>
               <h2>The Summit on AI in Society</h2>
                {/* <h3>Futures of Collective Trust, Understanding and Innovation</h3> */}
                <h4>October 13 - 15, 2022</h4>
                </Col>
                </Row>
             </Col>
              <Col xs={12}>
             <p>The University of Chicago's Institute on the Formation of Knowledge (IFK) is excited to announce The Summit on AI in Society on October 13-15, 2022. We will have outstanding scholars from computer science, economics, history, philosophy, and other fields. As AI continues to grow in its power and application, we plan to discuss the potential and limitations of AI knowledge, its origins and end-games, methods to ensure trustworthiness and understanding, and ensemble approaches for collective intelligence and knowledge production.</p>
                <button><a href="https://www.summitonai.org/" target="_blank">learn more</a></button> 

             </Col>
            </Col>


            <Col id="humanai" xs={12} md={5}>
              <Col xs={12}>
                <Row>
             
                 <Col xs={6}><img src={datasci} /></Col>
              <Col xs={6}><h2>Human + AI Conference</h2>
                {/* <h3>Futures of Collective Trust, Understanding and Innovation</h3> */}
               <h4>October 28, 2022</h4>
               </Col>
               </Row>
             </Col>

             <Col xs={12}>
            
             <p>AI has achieved impressive success in a wide variety of domains, ranging from medical diagnosis to creative image generation. This success provides rich opportunities for AI to address important societal challenges, but there are also growing concerns about the bias and harm that AI systems may cause. This conference brings together diverse perspectives to think about the best way for AI to fit into society and how to develop the best AI for humans. </p>
                <button><a href="http://datascience.uchicago.edu/events/human-ai-conference/" target="_blank">learn more</a></button> 
            </Col>
           </Col>

          </Row>        

      </Container>

    </section>
)
}
export default Events
