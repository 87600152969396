import logo from './img/trusted-logo.svg';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import './App.css';
import {useLottie} from 'lottie-react';
import animationData from './lotties/artificial.json';

const Hero = () => {

  // const options = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice"
  //   }
  // };

  // const { View } = useLottie(options);

  return (
    <section id="hero">
      <Container>
 
          <Row>
            <Visible sm md lg xl xxl>
              <Col md={3}></Col>
            </Visible>
      
            <Col sm={6}>
                 <h2>The Promise of <br />Artificial Intelligece</h2>
            </Col>
              
          </Row>
            

      {/* </Container>

     <Container> */}
             
            <Row>
            <Visible sm md lg xl xxl>
              <Col md={3}></Col>
            </Visible>
               <Col sm={6}>
               <p>is to improve the quality of life and prosperity for all people—from diverse backgrounds and lived experiences. How can we explore and approach this potential by designing and building AI suffuciently trusted and trustworthy to risk our safety, data, decisions, and futures.</p>
              

            </Col>

          </Row>

      </Container>

    </section>
  );
}

export default Hero;
