import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import './Research.css';
import model from './img/research-model.svg';
import spiral from './img/3d-spiral.svg';
import icon1 from './img/icon-1.svg';
import iconContract from './img/icon-contract.svg';
import iconTrust from './img/icon-trust.svg';
import iconContext from './img/icon-context.svg';

const Research= () => {
  return (
    <>
   <section className="research">    
    <Container id="research">
        <Row>
        <Hidden xs><Col sm={1} md={1}></Col></Hidden>

        <Col sm={4}>
           <h2>Research</h2>
           <p>The Institute for Trusted Intelligence in Society conceives of the relationship between humanity and AI as a dynamic social contract, which we seek to improve and update with three complementary streams of foundational AI research. </p>
        </Col>


        <Col sm={6} >

            <Row>
           <h3>The Social Contract Underlying Trust in AI</h3> 
            <p>We explore the nature of human trust and the values underlying it through (1) interactive observations and AI-assisted experiments with diverse populations and (2) analysis of interactions and operational tradeoffs among values within AI systems. </p>
            </Row>

            
            <Row>
             <h3>Personalizing the Contract for Warranted Trust</h3>
             <p>We enable warranted trust in AI through improved human-AI communication through (1) refining AI explanations for how it knows what it knows, including articulation of confusion and uncertainty; (2) making AI sensitive to human understanding and emotion to appropriately convey this insight, and (3) learning more from human responses to enable dynamic updating. </p>
                </Row>
           
            <Row>
                <h3>Satisfying the Contract for Trustworthiness</h3>
                <p>We ensure AI’s ability to deliver on human demands by (1) better juggling complex human objectives; (2) developing new deep audits of AI trustworthiness and practical benchmarks for a wide range of human values such as safety, reliability, privacy, fairness, friendliness and more; and (3) developing systems of AI self-governance, including AIs to train, test, monitor, correct and stabilize other AIs to ensure trustworthy compliance.</p>
            </Row>
            <Row>

            <h3>Use-Inspired Contexts</h3>
            <p>We undertake this alongside use-inspired research across a range of important AI systems in society, including embodied AI in self-driving cars and assistive robots and enabling AI that assists human decisions about critial topics such as hiring, justice, human health, research and development, and the moderation of content online.</p>
            </Row>
           
            
        </Col>
        
        

        </Row>
    </Container>

    </section>
    

     <section>
     <Container id="research2" >
         
         <Row>
           
             <Col sm={7} ><img src={model} /></Col>
             <Col sm={5}><p>&nbsp;</p></Col>
             
         </Row>
         <Row>
           <Col sm={5}><p>&nbsp;</p></Col>
           <Col sm={7} ><img src={spiral} /></Col>
         </Row>
         </Container>
     </section>
     </>

   
  );
}

export default Research;
