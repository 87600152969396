import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import './People.css';
import Peoples from './People.json'
import {AdobeFonts} from 'react-adobe-fonts'

const PeopleEach = Peoples;

const People = () => {
  return (
  <section id="people">    

    <Container>
        
        <Row>
        <Col className="people-head">
          <h2>People</h2>
          <p>We are led by computer scientists, roboticists, and mathematicians in engaged conversation with sociologists, psychologists, and philosophers from diverse backgrounds and lived experiences who seek to create AI that not only meets human trustworthiness as the standard, but exceeds it. </p>
        </Col>
        </Row>


        <Row>

            {/* <Hidden xs><Col sm={1}></Col></Hidden> */}
           
            {PeopleEach.people.map((props) => {
            
                if ((props.role === "Co-Primary Investigator") || (props.role === "Primary Investigator")){
                    return <Col key={props.title} className="people-each" xs={12} sm={6}>
                        <div style={{backgroundImage: `url(./img/${props.img})`}}>
                    <div className="container">
                    
                    <h3>{props.investigator}</h3>
                    <p className="role">{props.role}</p>
                    <p className="title"><em>{props.title}</em></p>
                    <p className="description">{props.description}</p>
                    </div>
                    </div>
                    
                </Col>
                }
            
})}
        </Row>
</Container>
<Container id="additionals">
        <Row>

         <h3>Collaborators</h3>
            </Row> <Row>
            {PeopleEach.people.map((props) => {
            
                if ((props.role === "Investigator")){
                    return <Col className="people-each" xs={12} sm={6} md={6} lg={4}>
                        <div>
                        <img src={`./img/${props.img}`}/>
                    <div className="container">
                    
                    <h3>{props.investigator}</h3>
                    {/* <p className="role">{props.role}</p>
                    <p className="title">{props.title}</p> */}
                    <p className="description">{props.description}</p>
                    </div>
                    </div>
                    
                </Col>
                }
            
            })}
        </Row>
            
     </Container>
            




    </section>
  );
}

export default People;
