import React, {useReducer, useState, useEffect} from 'react';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import Workshops from './Workshops.json'
import './Workshops.css';


const WorkshopEvents = Workshops;




const Workers = () => {

// const WorkshopEventsObject = WorkshopEvents.workshops.map((workshop, i) => ({
//     id: i, 
//     presentor: workshop.presentor,
//     date: workshop.date,
//     description: workshop.description
//   })) ;

 //console.log(Workshops.workshops)


  return (

   <section id="workshops">   
   
    

    <Container>
      <Row>

        <Col sm={3}><h2>Workshops</h2></Col>

       <Col sm={9}>
        
       

        {WorkshopEvents.workshops.map((props, i) => (
        <Row className="workshop-each">
          
            <Col sm={4} key={i}>
            <h3>{props.presentor}</h3>
            <p>{props.date}</p>
            </Col>

            <Col sm={8}>
            <p>{props.description}</p>
            </Col>
          
          </Row>
        
        ))}
        

       

       </Col>
      

        {/* <Col className="workshop-head" sm={3} md={4}>
            <h2>Workshops</h2>
        </Col>

        <Col className="workshop-each" sm={12} md={8}>
          <Row>
              <Col sm={4}>Presenter</Col>
              <Col sm={8}>Deatils</Col>
          </Row>

        </Col> */}
      
        
        </Row>
    </Container>

    </section>
    
  );
  }

export default Workers;
