import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import ReactPlayer from "react-player";
import './Mission.css';

const Mission = () => {

    
        const mystyle = {
            "--aspect-ratio": "16/9",
        };

  return (

    <section id="video">
    <Container>
        <Row>

        <Hidden xs><Col sm={1} md={1}></Col></Hidden>
        
        <Col className="mission" sm={12} md={3}>

          <h2>Our Mission</h2>

            <p>Our mission is to explore the human value systems underlying trust in AI, ensure complex AI competence to satisfy diverse values and enable trustworthiness, and improve human-AI communication to achieve warranted trust.</p>
        </Col>
        <Col id="videoContainer" sm={12} md={7} style={mystyle}>
            <ReactPlayer
                url="https://vimeo.com/743468089"
            />
        </Col> 

        </Row>
        <Row>
         
        
        </Row>

    </Container>
    </section>
  );
}

export default Mission;
