import React from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navigation from './Navigation';
import Hero from './Hero';
import Mission from './Mission';
import Events from './Events';
import Research from './Research';
import Papers from './Papers';
import Workers from './Workshops';
import People from './People';
import Footer from './Footer';
import Slideshow from './Slideshow';
import reportWebVitals from './reportWebVitals'
import { Container, Row, Col } from 'react-grid-system';;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>
  <Helmet>
  <title>Institute for Trusted Intelligence in Society</title>
  <meta name="description" content="The Institute for Trusted Intelligence in Society" />
  </Helmet>

  <React.StrictMode>
    <Navigation />
    <Hero />
    <Events />
    <Mission />
    <Research  />
    <Slideshow />
    <Papers />
    <Workers/>
    <People />
    <Footer />
  </React.StrictMode>
  </>

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
